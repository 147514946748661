$(document).ready(function() {
    const countries = $(".countries__item-name, .neopixl-comp");
    const countryPaths = $(".country-path");

    countries.on("mouseover mouseout", function () {
        const country = $(this).attr("data-country");
        const countryMap = $(`#${country}`);
        const toggleClass = "active";

        if (!country.length) return;

        if (countryMap.is(`.${toggleClass}`)) {
            countryMap.attr("fill-opacity", "0.4");
            countryMap.removeClass(toggleClass);
        } else {
            countryMap.attr("fill-opacity", "1");
            countryMap.addClass(toggleClass);
        }
    });

    countryPaths.on("mouseover mouseout", function () {
        const currentCountry = $(this);
        const country = currentCountry.attr("id");
        const countryItem = $(`[data-country='${country}']`);
        const toggleClass = "active";
        const hoverClass = "hover-country";

        if (!country) return;

        if (currentCountry.is(`.${toggleClass}`)) {
            currentCountry.attr("fill-opacity", "0.4");
            currentCountry.removeClass(toggleClass);
            countryItem.removeClass(hoverClass);
        } else {
            currentCountry.attr("fill-opacity", "1");
            currentCountry.addClass(toggleClass);
            countryItem.addClass(hoverClass);
        }
    });

    countryPaths.on('click', function () {
        const countryId = $(this).attr('id'),
            countryLink = $('.countries-page__sidebar').find(`[data-country='${countryId}']`);

        $(countryLink)[0].click();
    });
})